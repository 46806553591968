var Subscribers_ParcoursController = {
    init: function () {
        $("#Subscribers_Form_Share .form-element .dropdown .list").jScrollPane({
            autoReinitialise: true,
        });

        $("body").on("click", ".social-networks div", function (e) {
            e.preventDefault();
            $("#Subscribers_Form_Share #type").val($(this).data("type"));
            $(".social-networks div").removeClass("active");
            $(this).addClass("active");
            if ($(this).hasClass("email")) {
                $(".form-group.emails").slideDown();
            } else {
                $(".form-group.emails").slideUp();
            }
        });

        /**
         * Share
         */
        $("body").on(
            "click",
            '#Subscribers_Form_Share input[type="submit"]:not(".main-submit")',
            function (e) {
                e.preventDefault();
                $(this).parents(".form-element").removeClass("open");
            }
        );

        $("body").on("submit", "#Subscribers_Form_Share", function (e) {
            e.preventDefault();
            var $this = $(this);

            var val = [];
            $this.find('input[name="favorites[]"]:checked').each(function (i) {
                val[i] = $(this).val();
            });

            if (val.length == 0) return alert($this.data("empty-msg"));

            var idUser = $this.find("#id_user").val();
            var userType = $this.data("user-type");
            var param = $("#dataType").val() == "activities" ? "a" : "d";
            var url =
                $this.data("base-url") +
                "?" +
                userType +
                "=" +
                idUser +
                "&" +
                param +
                "=" +
                val.join();
            var urlTwitter =
                $this.data("twitter-url") +
                encodeURIComponent(
                    "?" +
                        userType +
                        "=" +
                        idUser +
                        "&" +
                        param +
                        "=" +
                        val.join()
                );

            switch ($("#Subscribers_Form_Share #type").val()) {
                case "email":
                    Popin.open(
                        $this.attr("action"),
                        $(this).serializeArray(),
                        "POST"
                    );
                    break;
                case "facebook":
                    FB.ui(
                        {
                            method: "feed",
                            name: $this.attr("data-title"),
                            link: url,
                            picture: $this.attr("data-picture"),
                            description: $this.attr("data-description"),
                        },
                        function (response) {
                            if (response && response.post_id) {
                                Popin.close();
                            } else {
                            }
                        }
                    );
                    break;
                case "twitter":
                    Twitter.open(urlTwitter);
                    break;
            }
        });

        /**
         * Cancel Reservation
         */
        $("body").on("click", ".form-cancel-reservation-submit", function (e) {
            var buttonId = $(this).attr("id");
            var idMatch = buttonId.match(
                /form-cancel-reservation-submit-(\d+)/
            );
            if (idMatch && idMatch[1]) {
                var reservationId = idMatch[1];
                $(`#form-cancel-reservation-${reservationId}`).submit();
            } else {
                console.error("Reservation ID not found in button ID.");
            }
        });
        const SHOW_SECTION_MOTIVES = [
            "want_reservation_moved",
            // "want_reservation_changed",
        ];
        const showMoveSection = (reservationId, show = true) => {
            if (show) {
                // hide textarea
                $(
                    `#customer-cancel-booking-dialog-${reservationId} .form-group.motive_text:not(.hidden)`
                ).addClass("hidden");
                // disable confirm btn
                $(
                    `#customer-cancel-booking-dialog-${reservationId} .form-cancel-reservation-submit`
                )
                    .prop("disabled", true)
                    .addClass("btn-disabled");
                // show section
                $(`#edit-section-${reservationId}`).removeClass("hidden");
            } else {
                // hide section
                $(`#edit-section-${reservationId}:not(.hidden)`).addClass(
                    "hidden"
                );
                // show textarea
                $(
                    `#customer-cancel-booking-dialog-${reservationId} .form-group.motive_text.hidden`
                ).removeClass("hidden");
                // enable confirm btn
                $(
                    `#customer-cancel-booking-dialog-${reservationId} .form-cancel-reservation-submit`
                )
                    .prop("disabled", false)
                    .removeClass("btn-disabled");
            }
        };
        $("body").on("change", ".cancel-reservation-motive", function (e) {
            var selectId = $(this).attr("id");
            var idMatch = selectId.match(/cancel-reservation-motive-(\d+)/);
            if (idMatch && idMatch[1]) {
                var reservationId = idMatch[1];
                showMoveSection(
                    reservationId,
                    SHOW_SECTION_MOTIVES.includes($(this).val())
                );
            } else {
                console.error("Reservation ID not found in select ID.");
            }
        });
        $("body").on("click", ".proceed-with-cancellation", function (e) {
            var buttonId = $(this).attr("id");
            var idMatch = buttonId.match(/proceed-with-cancellation-(\d+)/);
            if (idMatch && idMatch[1]) {
                var reservationId = idMatch[1];
                showMoveSection(reservationId, false);
            } else {
                console.error("Reservation ID not found in button ID.");
            }
        });
        $("body").on("submit", ".form-cancel-reservation", function (e) {
            e.preventDefault();
            var form = $(this);
            var formId = $(this).attr("id");
            var idMatch = formId.match(/form-cancel-reservation-(\d+)/);
            if (idMatch && idMatch[1]) {
                var reservationId = idMatch[1];

                form.closest(".dialog-content").addClass(
                    "ajaxify-container loading"
                );
                $(`#form-cancel-reservation-submit-${reservationId}`)
                    .prop("disabled", true)
                    .addClass("btn-disabled");

                $.ajax({
                    url: $(this).attr("action"),
                    data: $(this).serialize(),
                    type: "POST",
                    dataType: "json",
                })
                    .done(function (result) {
                        form.closest(".dialog-content").removeClass(
                            "ajaxify-container loading"
                        );
                        $(`#form-cancel-reservation-submit-${reservationId}`)
                            .prop("disabled", false)
                            .removeClass("btn-disabled");

                        if (!result.success) {
                            if (result.form !== undefined) {
                                form = $(result.form);
                                $(
                                    `#form-cancel-reservation-${reservationId}`
                                ).replaceWith(form);
                            } else {
                                if (result.message !== undefined) {
                                    alert(result.message);
                                }
                            }

                            if (result.warning !== undefined) {
                                alert(result.warning);
                                window.location.reload();
                            }
                            return;
                        }

                        window.location.reload();
                    })
                    .always(function () {
                        form.closest(".dialog-content").removeClass(
                            "ajaxify-container loading"
                        );
                        $(`#form-cancel-reservation-submit-${reservationId}`)
                            .prop("disabled", false)
                            .removeClass("btn-disabled");
                    });
            } else {
                console.error("Reservation ID not found in form ID.");
            }
        });
    },
};
